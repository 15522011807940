import React, {useEffect} from 'react';


export const Banner300x250 = ({width = 300, height = 250}) => {
    const adKey = "a9aafd239e2554cf53064e081e33d9be"
    return (
        <ShowAds adKey={adKey} width={width} height={height}/>
    );
};


export const Banner728x90 = ({width = 728, height = 90}) => {
    const adKey = "077393abc9421d8d6efd4a60cb0b7ea7"

    return (
        <ShowAds adKey={adKey} width={width} height={height}/>
    );
};


export const Banner468x60 = ({width = 468, height = 60}) => {
    const adKey = "0773b49c332d9cb70cca868c3480f45a"
    return (
        <ShowAds adKey={adKey} width={width} height={height}/>
    );
};


export const Banner320x50 = ({width = 320, height = 50}) => {
    const adKey = "3d15544a55bb9de29db394b67368221c"
    return (
        <ShowAds adKey={adKey} width={width} height={height}/>
    );
};


export const Banner160x300 = ({width = 160, height = 300}) => {
    const adKey = "4822e89bb7933daf791fbba236c0d7b7"
    return (
        <ShowAds adKey={adKey} width={width} height={height}/>
    );
};


export const Banner160x600 = ({width = 160, height = 600}) => {
    const adKey = "864f1a2bc60f62c26fb6e72f2cdfbaf8"
    return (
        <ShowAds adKey={adKey} width={width} height={height}/>
    );
};


const ShowAds = ({adKey, width, height}) => {

        let isLocalhost = window.location.hostname === 'localhost';

        // isLocalhost = false;


        if (isLocalhost) {
            return (
                <div
                    className={`bg-gray-300 border border-gray-400 flex items-center justify-center`}
                    style={{width: `${width}px`, height: `${height}px`}}
                >
                    <h1>{width} x {height}</h1>
                </div>
            );
        } else {
            return <Ads adKey={adKey} width={width} height={height}/>;
        }
    }
;


const Ads = ({adKey, width, height}) => {

    const id = `atContainer-${adKey}-${Math.floor(Math.random() * 100000)}`;

    useEffect(() => {


        if (typeof window.atAsyncOptions !== 'object') {
            window.atAsyncOptions = [];
        }

        window.atAsyncOptions.push({
            key: adKey,
            format: 'js',
            async: true,
            container: id,
            params: {},
        });

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `http${window.location.protocol === 'https:' ? 's' : ''}://www.highperformanceformat.com/${adKey}/invoke.js`;

        document.head.appendChild(script);

    }, [adKey]);
    return <div id={id}/>;

}
