import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'remixicon/fonts/remixicon.css';
import './index.css';
import {NextUIProvider} from "@nextui-org/react";
import Example from "./test";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <NextUIProvider>
            <App/>
            {/*<Example/>*/}
        </NextUIProvider>
    </>
);
