import {fetchDownload} from "../../utils/moviedb";
import React, {useEffect, useState} from "react";
import axios from "axios";
import PreLoader from "../PreLoader";
import {Button, Spinner} from "@nextui-org/react";

const DownloadData = ({id}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [download, setDownload] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expandedIndex, setExpandedIndex] = useState(null);

    useEffect(() => {
        setIsLoaded(false);

        getDownload(id).then(() => {
            setIsLoaded(true);
        });
    }, [id]);

    const getDownload = async (id) => {
        try {
            setLoading(true);
            const result = await fetchDownload(id);
            setDownload(result.data);
        } catch (error) {
            console.error("Error fetching download data:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchMagnetLink = async (url) => {
        try {
            const response = await axios.get(url);
            return response.data.magnet;
        } catch (error) {
            console.error("Error fetching magnet link:", error);
            return null;
        }
    };

    const handleDownloadClick = async (link) => {
        setLoading(true);
        try {
            if (link.startsWith("magnet")) {
                window.open(link, "_blank");
            } else {
                const magnet = await fetchMagnetLink(link);
                if (magnet) {
                    window.open(magnet, "_blank");
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const handleExpandClick = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(null); // Collapse if already expanded
        } else {
            setExpandedIndex(index); // Expand the selected index
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center">
                <Spinner color={"primary"} label={"Loading..."} labelColor={"primary"} size={"lg"}/>
            </div>
        );
    }

    const mutateTitle = (title) => {
        if (!title) {
            return "";
        }
        if (title.length > 30) {
            return title.slice(0, 30) + "...";
        }
        return title;
    }


    return (
        <div>

            {/* <div className="grid max-sm:grid-cols-1 grid-cols-3 gap-6"> */}
            <div className="grid gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">   

                {download.map((item, index) => (

                    <Button
                        variant={"bordered"}
                        className="shadow-lg rounded-lg overflow-hidden flex flex-col h-full w-full"
                        style={{ border: "1px solid #F59E0B", borderRadius: "13px" }}
                        onPress={() =>
                            handleDownloadClick(
                                item.magnet === "" ? item.url : item.magnet
                            )
                        }
                    >

                        <div
                            className="absolute right-0 top-0 bg-primary text-black text-xs px-2 py-1 rounded-tr-lg rounded-bl-lg">
                            {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                        </div>

                        <div className="p-4 mt-2">
                            <h2 className="text-md font-semibold mb-2 text-primary-300">
                                {mutateTitle(item.name)}
                            </h2>

                            <div className={"flex flex-col border-t border-primary "}>
                                <div className="flex justify-evenly flex-row text-enter text-primary-200">
                                    <div className="flex flex-col  text-sm ">
                                        <span>Seeder</span>
                                        <span>{item.seeder}</span>
                                    </div>

                                    <div className="flex flex-col  text-sm ">
                                        <span>Leecher</span>
                                        <span>{item.leecher}</span>
                                    </div>

                                    <div className="flex flex-col  text-sm ">
                                        <span>Size</span>
                                        <span>{item.size}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="px-4 py-3 mt-auto">
                            <Button
                                onPress={() =>
                                    handleDownloadClick(
                                        item.magnet === "" ? item.url : item.magnet
                                    )
                                }
                                className="w-full bg-green-500 hover:bg-green-600 text-white py-2 rounded-2xl"
                            >
                                <i className="ri-download-line"></i> Download
                            </Button>
                        </div>

                    </Button>


                ))}
            </div>
        </div>
    );
};

export default DownloadData;