import {useParams} from "react-router-dom";
import {fetchMovieDetails} from "../utils/moviedb";
import React, {useEffect, useState} from "react";
import DownloadData from "../Components/Download/DownloadData";
import {MovieDownloadHead} from "../Components/DynamicHead";
import PreLoader from "../Components/PreLoader";
import {TopAds} from "../ads/AdsContainer";


const Download = () => {
    const {query} = useParams();

    const id = query.split('-')[0];

    const [movie, setMovie] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {
        setIsLoaded(false)

        getMovieDetails(id).then(() => {
            setIsLoaded(true)
        })

    }, [id]);

    const getMovieDetails = async (id) => {
        const movieDetailsData = await fetchMovieDetails(id)
        setMovie(movieDetailsData)
    }

    if (!isLoaded) {
        return <PreLoader className={"h-screen"}/>
    }

    return (
        <header className='page-header flex'>
            <div className='container'>
                <div className={"flex justify-center"}>
                    <TopAds/>
                </div>

                {isLoaded ? (
                    <div>
                        <MovieDownloadHead movie={movie}/>
                        <div className='flex flex-col justify-start items-center flex-wrap '>
                        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-yellow-500 text-center my-6">Download {movie.title}</h2>
                            <div className="">
                                <div className={"flex justify-center mb-6"}>
                                    <TopAds/>
                                </div>
                                <DownloadData query={query} id={id}/>
                                <div className={"flex justify-center my-6"}>
                                    <TopAds/>
                                </div>
                            </div>
                        </div>
                    </div>

                ) : null}
            </div>
        </header>);
}

export default Download;