import React, {useEffect, useState} from 'react';
import logo from '../../images/logo.png';
import {
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    Link,
    NavbarMenuToggle,
    NavbarMenu, Tabs, Tab, Button
} from "@nextui-org/react";
import {IoMenu} from "@react-icons/all-files/io5/IoMenu";
import {IoClose} from "@react-icons/all-files/io5/IoClose";
import {IoSearch} from "@react-icons/all-files/io5/IoSearch";


const CUSTOMNavbar = ({setShowSearch, navPathName, setNavPathName}) => {
    const [showSide, setShowSide] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);


    useEffect(() => {
        if (showSide) {
            document.body.style = 'overflow: hidden';
        }

        return () => {
            document.body.style = 'overflow: auto';
        }
    }, [showSide]);

    const handleShowSearch = () => {
        setShowSide(false);
        setShowSearch(true);
        setNavPathName("");
    }


    const handleTabChange = (key) => {
        setIsMenuOpen(false);
        if (key === "search") {
            handleShowSearch();
            return;
        }
        setNavPathName(key);
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Adds a smooth scrolling animation
        });
    };


    return (
        <Navbar isBordered className={"bg-[#171d22D0]"} isMenuOpen={isMenuOpen}>
            <NavbarContent className="lg:hidden" justify="start">
                <NavbarMenuToggle icon={isOpen => {
                    if (isOpen) {
                        return <IoClose className="text-primary text-5xl"/>

                    } else {
                        return <IoMenu className="text-white text-5xl"/>
                    }
                }}
                                  onChange={setIsMenuOpen}
                />
            </NavbarContent>

            <NavbarContent className="lg:hidden pr-3" justify="center">
                <NavbarBrand>
                    <Link href={"/"}>
                        <img src={logo} alt='Watch Movies Time' className='w-36'/>
                    </Link>
                </NavbarBrand>
            </NavbarContent>

            <NavbarContent className="hidden lg:flex gap-4" justify="center">
                <NavbarBrand>
                    <Link href={"/"}>
                        <img src={logo} alt='Watch Movies Time' className='w-36'/>
                    </Link>
                </NavbarBrand>

                <NavbarItem>

                    <Tabs color={"primary"} variant={"underlined"} selectedKey={navPathName}
                          onSelectionChange={handleTabChange}
                          classNames={{
                              tabContent: ("text-white"),
                          }}
                    >
                        <Tab key="daily-trending" title="Daily Trending"/>
                        <Tab key="weekly-trending" title="Weekly Trending"/>
                        <Tab key="now-playing" title="Now Playing"/>
                        <Tab key="popular" title="Popular"/>
                        <Tab key="top-rated" title="Top Rated"/>
                        <Tab key="upcoming" title="Upcoming"/>
                        <Tab key="" title="" className={"collapse"}/>
                    </Tabs>

                </NavbarItem>

            </NavbarContent>

            <NavbarContent justify="end">

                <NavbarItem className="hidden lg:flex">
                    <Button isIconOnly aria-label="Like" color="primary" variant={"ghost"}
                            onPress={handleShowSearch}>
                        <IoSearch className={"text-white text-2xl"}/>
                    </Button>
                </NavbarItem>
            </NavbarContent>

            <NavbarMenu className={"bg-[#171d22E0]"}>

                <Tabs
                    color={"primary"}
                    variant={"underlined"}
                    selectedKey={navPathName}
                    onSelectionChange={(key) => {
                        handleTabChange(key);
                    }}
                    isVertical={true}
                    className={"w-full h-full justify-center items"}
                    classNames={{
                        tabContent: ("text-white text-xl"),
                        tab: ("py-7"),
                    }}
                >
                    <Tab key="daily-trending" title="Daily Trending"/>
                    <Tab key="weekly-trending" title="Weekly Trending"/>
                    <Tab key="now-playing" title="Now Playing"/>
                    <Tab key="popular" title="Popular"/>
                    <Tab key="top-rated" title="Top Rated"/>
                    <Tab key="upcoming" title="Upcoming"/>
                    <Tab key="search" title="Search"/>
                    <Tab key="" title="" className={"collapse hidden"}/>
                </Tabs>


            </NavbarMenu>
        </Navbar>
    )
};

export default CUSTOMNavbar;