import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import MovieCard from '../Components/MovieCard';
import NoData from '../Components/Search/noData';
import {fetchMovieSearch} from "../utils/moviedb";
import LoadMoreAnimation from "../Components/LoadingAnimations/LoadMoreAnimation";
import {AdsSearchLeftSide, AdsSearchRightSide, TopAds} from "../ads/AdsContainer";

const SearchPage = () => {
    const {query} = useParams();
    const [movieData, setMovieData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setMovieData([]); // Clear old data for new searches
        setCurrentPage(1); // Reset page
    }, [query]);

    useEffect(() => {
        setIsLoaded(false);
        fetchSearchResults(); // Fetch results on query or page change
    }, [query, currentPage]);

    const fetchSearchResults = async () => {
        try {
            const data = await fetchMovieSearch(query, currentPage);
            if (currentPage === 1) {
                setMovieData(data.results); // For new searches, replace old data
            } else {
                setMovieData((prevData) => [
                    ...prevData,
                    ...data.results.filter((movie) => !prevData.some((m) => m.id === movie.id)), // Avoid duplicates
                ]);
            }
            setTotalPages(data.total_pages);
            setIsLoaded(true);
        } catch (error) {
            console.error('Error fetching search results:', error);
            setIsLoaded(true);
        }
    };

    const nextPage = () => {
        setCurrentPage((page) => page + 1);
    };

    return (
        <>
            <section className='results-sec'>
                <div className='container'>
                    <div className='section-title'>
                        <h2
                            className="text-3xl max-sm:text-2xl md:text-5xl lg:text-6xl mb-4 text-[#F5A524] pt-4 text-center">
                            {query}'s Related Results
                        </h2>

                    </div>
                    <div className={"flex justify-center mb-6"}>
                        <TopAds/>
                    </div>
                    {movieData.length ? (
                        <div className="flex flex-wrap justify-center">
                            <div>
                                <AdsSearchLeftSide/>
                            </div>
                            <div className={"flex-1 flex flex-wrap justify-center gap-4"}>
                                {movieData.map((movie) => (
                                    <MovieCard movie={movie} key={movie.id}/>))}
                            </div>
                            <div>
                                <AdsSearchRightSide/>
                            </div>
                        </div>
                    ) : (<NoData/>)}
                    {!isLoaded && <LoadMoreAnimation/>}

                    <div className={"flex justify-center my-6"}>
                        <TopAds/>
                    </div>

                    {isLoaded && currentPage < totalPages &&
                        <div className='text-center cursor-pointer'>
                            <button
                                onClick={nextPage}
                                className="hover:bg-gray-50 bg-yellow-400 text-[#1B252E] font-bold py-2 px-4 border border-white rounded">
                                Load More....
                            </button>
                        </div>
                    }
                </div>
            </section>

        </>
    );
};

export default SearchPage;