import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {convertMinutesToHours, fetchMovieDetailsAll, image500} from "../../utils/moviedb";
import {MovieDetailHead} from "../../Components/DynamicHead";
import PosterWithPlaceholder from "../../Components/MovieCard/PosterWithPlaceholder";
import BackdropWithPlaceholder from "../../Components/MovieCard/BackdropWithPlaceholder";
import {DownloadNavigation} from "../../Navigation";
import SimilarMovies from "./components/SimilarMovies";
import {Chip} from "@nextui-org/chip";
import PreLoader from "../../Components/PreLoader";
import {
    AdsMovieDetailLeftSide,
    AdsMovieDetailRightSide,
    LeftSideAds,
    RightSideAds,
    TopAds
} from "../../ads/AdsContainer";


const Index = () => {
    const {query} = useParams();

    const id = query.split('-')[0];

    const [isLoaded, setIsLoaded] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [movie, setMovie] = useState('');

    let lastWord = movie ? movie.title.split(' ').pop() : '';

    useEffect(() => {
        setIsLoaded(false)

        getMovieDetails(id).then(() => {
            setIsLoaded(true)
        })
    }, [id]);

    const getMovieDetails = async (id) => {
        const movieDetailsData = await fetchMovieDetailsAll(id)
        setMovie(movieDetailsData)
    }

    if (!isLoaded) {
        return <PreLoader className={"h-screen"}/>
    }

    return (
        <>
            <header className='pt-20 flex text-center flex-wrap justify-center mb-16'>
                <div className={"flex justify-center mb-6"}>
                    <TopAds/>
                </div>
                <div className='container'>
                    {isLoaded ? (
                        <div>
                            <MovieDetailHead movie={movie}/>
                            <div className='flex justify-start items-center flex-wrap sm:justify-center'>
                                <div className='hidden lg:block'>
                                    <AdsMovieDetailLeftSide/>
                                </div>
                                <div className="flex flex-col min-[1335px]:flex-row items-center">

                                    {/* Picture */}
                                    <div className="flex">
                                        {/* PosterWithPlaceholder visible only on screens > 1335px */}
                                        <div className="w-80 ml-3 lg:ml-6 hidden min-[1335px]:block">
                                            <PosterWithPlaceholder src={image500(movie.poster_path)}/>
                                        </div>
                                        {/* BackdropWithPlaceholder visible only on screens <= 1335px */}
                                        <div className="w-72 block min-[1335px]:hidden">
                                            <BackdropWithPlaceholder src={image500(movie.backdrop_path)}/>
                                        </div>
                                    </div>

                                    {/* Details */}
                                    <div className="max-w-xl mx-3 lg:mx-6">
                                        {/* Title */}
                                        <h2 className="text-3xl max-sm:text-2xl md:text-5xl lg:text-6xl mb-4 text-[#F5A524] pt-4 text-center min-[1335px]:text-justify">
                                            {movie.title.split(' ').slice(0, -1).join(' ')}
                                            <span className="text-[#F5A524]">{lastWord}</span>
                                        </h2>

                                        {/* Meta */}
                                        <div className="banner-meta">
                                            <ul className="flex list-none flex-wrap justify-center min-[1335px]:justify-start">

                                                {/* Language/Quality */}
                                                <li className="flex items-center mb-4 mr-5">
                                                    <span
                                                        className="text-[10px] sm:text-[11px] uppercase text-[#21232b] bg-white font-bold px-[8px] sm:px-[11px] py-[5px] sm:py-[7px] leading-none">
                                                        {movie.original_language}
                                                    </span>
                                                    <span
                                                        className="ml-4 text-[10px] sm:text-[11px] uppercase text-white bg-[#21232b] font-bold px-[7px] sm:px-[9px] py-[4px] sm:py-[5px] leading-none border-white border-2">
                                                        HD
                                                    </span>
                                                </li>

                                                {/* Genre */}
                                                <li className="flex items-center mb-4 mr-5">
                                                    {movie.genres.map((genre, index) => (
                                                        <Chip
                                                            key={genre.id}
                                                            color={"primary"}
                                                            variant={"bordered"}
                                                            className={"mx-0.5"}
                                                        >
                                                            {genre.name}
                                                        </Chip>
                                                    ))}
                                                </li>

                                                {/* Release Date/Runtime */}
                                                <li className="flex items-center mb-4 mr-5">
                                                    <span className="text-xs sm:text-sm font-medium text-[#e3dfdf]">
                                                        <i className="ri-calendar-2-line text-[10px] sm:text-[12px] mr-1 text-[#F5A524]"></i>
                                                        {movie.release_date}
                                                    </span>
                                                    <span
                                                        className="ml-4 text-xs sm:text-sm font-medium text-[#e3dfdf]">
                                                        <i className="ri-time-line text-[10px] sm:text-[12px] mr-1 text-[#F5A524]"></i>
                                                        {convertMinutesToHours(movie.runtime)}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>

                                        {/* Overview */}
                                        <div className="mb-4 flex flex-col items-start">
                                            <div
                                                className="text-xs sm:text-sm font-medium leading-[22px] sm:leading-[26px] text-[#bcbcbc] text-left flex flex-wrap items-center">
                                                <p className="inline">
                                                    {isExpanded
                                                        ? `${movie.overview} `
                                                        : `${movie.overview?.slice(0, 300)}... `}
                                                </p>
                                                {movie.overview && movie.overview.length > 300 && (
                                                    <button
                                                        className="hover:underline text-white cursor-pointer text-xs sm:text-sm"
                                                        onClick={() => setIsExpanded(!isExpanded)}
                                                    >
                                                        {isExpanded ? 'Read Less' : 'Read More'}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className='flex min-[1335px]:justify-start justify-center'>
                                            <DownloadNavigation
                                                className="download-btn"
                                                movie_id={movie.id}
                                                movie_title={movie.title}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='hidden lg:block'>
                                    <AdsMovieDetailRightSide/>
                                </div>
                            </div>

                            <div className={"flex justify-center my-6"}>
                                <TopAds/>
                            </div>
                            <div
                                className="flex justify-evenly"
                            >
                                <div>
                                    <LeftSideAds/>
                                    <LeftSideAds/>
                                </div>

                                <div>
                                    <SimilarMovies id={id} movieTitle={movie.title}/>
                                </div>

                                <div>
                                    <RightSideAds/>
                                    <RightSideAds/>
                                </div>
                            </div>
                        </div>

                    ) : null}
                </div>
            </header>
        </>
    )
}

export default Index;