import React from 'react';
import {TopAds} from "../../ads/AdsContainer";

const Footer = () => {
    return (
        <>
            <div className={"flex justify-center mb-6"}>
                <TopAds/>
            </div>
            <footer className="bg-gray-900 text-gray-300 py-10">

                <div className="container mx-auto px-6">
                    {/* Top Section */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
                        {/* Quick Links */}
                        <div>
                            <h3 className="text-lg font-semibold text-white mb-4">Quick Links</h3>
                            <ul className="space-y-2">
                                <li><a href="#" className="hover:text-gray-400">Now Showing</a></li>
                                <li><a href="#" className="hover:text-gray-400">Coming Soon</a></li>
                                <li><a href="#" className="hover:text-gray-400">Top Rated</a></li>
                                <li><a href="#" className="hover:text-gray-400">Genres</a></li>
                            </ul>
                        </div>

                        {/* Customer Support */}
                        <div>
                            <h3 className="text-lg font-semibold text-white mb-4">Customer Support</h3>
                            <ul className="space-y-2">
                                <li><a href="#" className="hover:text-gray-400">FAQ</a></li>
                                <li><a href="#" className="hover:text-gray-400">Contact Us</a></li>
                                <li><a href="#" className="hover:text-gray-400">Terms of Service</a></li>
                                <li><a href="#" className="hover:text-gray-400">Privacy Policy</a></li>
                            </ul>
                        </div>

                        {/* Disclaimer */}
                        <div>
                            <h3 className="text-lg font-semibold text-white mb-4">Disclaimer</h3>
                            <p className="text-sm">
                                This site does not store any files on its server. All contents are provided by
                                non-affiliated third parties.
                            </p>
                        </div>
                    </div>

                    {/* Divider */}
                    <hr className="border-gray-700 my-8"/>

                    {/* Bottom Section */}
                    <div
                        className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-6">
                        <p className="text-sm">© 2024 MovieWebsite. All rights reserved.</p>
                        <div className="flex flex-wrap justify-center space-x-4">
                            <a href="#" className="hover:text-gray-400">Download App</a>
                            <a href="#" className="hover:text-gray-400">English (US)</a>
                        </div>
                    </div>
                </div>

                {/* Mobile View Adjustment */}
                <style>{`
        @media (max-width: 768px) {
          .grid {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 25px;
            margin-left: 10%;
            margin-right: 10%;
          }
        }
      `}</style>
            </footer>
        </>
    );
};

export default Footer;