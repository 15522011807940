import { Link } from "react-router-dom";
import React from "react";


export const DownloadNavigation = ({ movie_id, movie_title }) => {
    return (
        <Link
            to={`/movie/${movie_id}-${slugify(movie_title)}/download`}
            className="flex items-center justify-center text-[10px] sm:text-[12px] font-bold tracking-[1px] uppercase leading-[1.5] whitespace-nowrap max-w-[180px] sm:max-w-[220px] mb-4 px-4 sm:px-5 py-3 sm:py-4 bg-[#12151e] border-2 border-[#F5A524] text-[#e3dfdf] rounded-full cursor-pointer select-none hover:bg-[#F5A524] hover:text-[#12151e] transition-all duration-300 shadow-md sm:shadow-lg"
            target="_blank"
            rel="noreferrer"
        >
            <div className="mx-7 flex items-center">
                <i className="ri-download-line text-[14px] sm:text-[16px] mr-2"></i>
                Download
            </div>
        </Link>
    );
};

export const MovieNavigation = ({ movie_id, movie_title, children }) => {
    return (
        <Link
            to={`/movie/${movie_id}-${slugify(movie_title)}`}
            className="w-full h-full">
            {children}
        </Link>
    )
}


// function to make string in url friendly format
export const slugify = (string) => {
    return string.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
}