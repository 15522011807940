import posterplaceholder from '../../images/placeholder.png';
import React from "react";
import {Image} from "@nextui-org/react";


const PosterWithPlaceholder = ({src}) => {
    return (
        <div className="h-full object-cover z-0">
            <Image
                src={src}
                alt="movie-poster"
                className="object-cover z-0 rounded-lg
                aspect-poster
                max-sm:w-24
                max-md:w-36
                max-lg:w-44

                 "
                loading="eager"
                fallbackSrc={posterplaceholder}
            />

        </div>
    )
}

export default PosterWithPlaceholder;