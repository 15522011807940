import {Spinner} from "@nextui-org/react";
import React from "react";

const PreLoader = ({className}) => {
    return (
        <section className={"flex text-center justify-center items-center h-[80vh] w-screen " + className}>
            <Spinner color={"primary"} label={"Loading..."} labelColor={"primary"} size={"lg"}/>
        </section>
    );
}

export default PreLoader;