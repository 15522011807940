import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import Footer from './Components/Footer';
import CUSTOMNavbar from './Components/Navbar';
import Home from './Pages/Home';
import NotFound from './Pages/NotFound';
import SearchPage from './Pages/SearchPage';
import MovieDetails from './Pages/DetailPage';
import Search from './Components/Search';
import Hello from "./Pages/Hello";
import Download from "./Pages/Download";

function App() {
    const [showSearch, setShowSearch] = useState(false);
    const [navPathName, setNavPathName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        if (navPathName === "") return;
        if (navPathName === "search") return;
        navigate(`/${navPathName}/1`);
        setCurrentPage(1);

    }, [navPathName]);


    useEffect(() => {


        const path = location.pathname.split('/')[1];
        if (path !== "movie" && path !== "search") {
            setNavPathName(path);
        }

    }, [location.pathname]);


    return (
        <>
            <CUSTOMNavbar setShowSearch={setShowSearch} navPathName={navPathName} setNavPathName={setNavPathName}/>
            <Search showSearch={showSearch} setShowSearch={setShowSearch} setCurrentPage={setCurrentPage}/>
            <Routes>
                <Route path='/' element={<Home currentPage={currentPage} setCurrentPage={setCurrentPage}
                                               setNavPathName={setNavPathName}/>}/>
                <Route path='/:type' element={<Home currentPage={currentPage} setCurrentPage={setCurrentPage}
                                                    setNavPathName={setNavPathName}/>}/>
                <Route path='/:type/:page' element={<Home currentPage={currentPage} setCurrentPage={setCurrentPage}
                                                          setNavPathName={setNavPathName}/>}/>

                <Route path='/search/:query' element={<SearchPage/>}/>
                <Route path='/movie/:query' element={<MovieDetails/>}/>
                <Route path='movie/:query/download' element={<Download/>}/>
                <Route path='*' element={<NotFound/>}/>
                {/*<Route path='/hello' element={<Hello/>}/>*/}
            </Routes>
            <Footer/>
        </>
    );
}

function AppWrapper() {
    return (
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    );
}

export default AppWrapper;