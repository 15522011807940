import React, {useEffect, useState} from "react";
import {fetchSimilarMovies} from "../../../utils/moviedb";
import MovieCard from "../../../Components/MovieCard";
import LoadMoreAnimation from "../../../Components/LoadingAnimations/LoadMoreAnimation";
import {LeftSideAds, RightSideAds, TopAds} from "../../../ads/AdsContainer";

const SimilarMovies = ({id, movieTitle}) => {


    const [movieData, setMovieData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [totalPages, setTotalPages] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setIsLoaded(false);
        getSimilarMovies().then(() => setIsLoaded(true));
    }, [currentPage]);


    const getSimilarMovies = async () => {
        const data = await fetchSimilarMovies(id, currentPage);
        const uniqueArray = [...movieData, ...data.results].filter((item, index, self) => {
            return self.indexOf(item) === index;
        });
        setMovieData(uniqueArray);
        setTotalPages(data.total_pages);
    }

    const nextPage = () => {
        setCurrentPage(pageNumber => pageNumber + 1);
    }

    return (<div
        className={"max-w-[940px] mx-auto px-4"}>
        <div
            className="flex flex-wrap xs:gap-4 gap-[14px] justify-center my-5"
        >
            {movieData.map((movie, index) => (<div
                    className="flex flex-col xs:gap-4 gap-2 lg:mb-6 md:mb-5 sm:mb-4 mb-[10px]"
                    key={movie.id}>
                    <MovieCard movie={movie}/>
                </div>))}
        </div>
        {!isLoaded && <LoadMoreAnimation/>}

        {isLoaded && currentPage < totalPages && <div className='text-center cursor-pointer mt-8'>
            <button
                onClick={nextPage}
                className="hover:bg-gray-50 bg-yellow-400 text-[#1B252E] font-bold py-2 px-4 border border-white rounded">
                Load More....
            </button>
        </div>}
    </div>)

}

export default SimilarMovies;