import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const Search = ({showSearch, setShowSearch, setCurrentPage}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const searchPage = useNavigate();

    const sendSearchQuery = (e) => {
        e.preventDefault();
        if (searchQuery === '') {
            alert('Please enter a search query');
        } else {
            searchPage(`/search/${searchQuery}`);
            setShowSearch(false);
            setCurrentPage(1);
            setSearchQuery('');
        }
    };

    const hideShowSearch = (e) => {
        e.preventDefault();
        if (!e.target.closest('.search-form')) {
            setShowSearch(prev => false);
        }
        return;
    }

    useEffect(() => {
        if (showSearch) {
            document.body.style = 'overflow: hidden';
        }

        return () => {
            document.body.style = 'overflow: auto';
        }
    }, [showSearch]);

    return (
        <div
            className={showSearch ? 'fixed top-0 left-0 w-full h-full bg-[rgba(23,26,33,0.95)] z-50 flex justify-center items-center p-4 transition-all duration-500 ease-in-out opacity-100 visible' : 'fixed top-0 left-0 w-full h-full bg-[rgba(23,26,33,0.95)] z-[99999] flex justify-center items-center p-4 opacity-0 invisible transition-all duration-500 ease-in-out'}
            onClick={(e) => hideShowSearch(e)}>
            <form
                className='search-form w-full max-w-[600px] border-b-[3px] border-[rgba(255,255,255,0.1)] flex justify-between items-center px-6'>
                <input
                    className='inline-block py-2 text-[1.7rem] font-medium bg-transparent text-white tracking-[0.5px] w-[calc(100%-60px)] flex-1'
                    type='text'
                    placeholder='Search'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    autoFocus
                />
                <button className='link btn search-btn  text-[1.7rem] font-medium bg-transparent text-[#F5A524]'
                        onClick={(e) => sendSearchQuery(e)}>
                    <i className='ri-search-line'></i>
                </button>
            </form>
        </div>
    );
};

export default Search;
