import React from "react";
import {Desktop, IPadPro, Mobile, Tablet} from "../MediaQuery";
import {
    Banner160x300,
    Banner160x600,
    Banner300x250,
    Banner320x50,
    Banner468x60,
    Banner728x90
} from "./Ads";
import {useMediaQuery} from "react-responsive";

export const TopAds = () => {
    return (
        <>
            <Desktop>
                <Banner728x90/>
            </Desktop>

            <Mobile>
                <Banner320x50/>
            </Mobile>

            <Tablet>
                <Banner468x60/>
            </Tablet>
        </>
    );
};

export const PaginationBottomAds = () => {
    return (
        <TopAds/>
    )
}

export const PaginationTopAds = () => {
    return (
        <TopAds/>
    )
}


export const LeftSideAds = () => {

    const isIPadPro = useMediaQuery({minWidth: 1024, maxWidth: 1366})

    if (isIPadPro) {
        return (
            <IPadPro>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
            </IPadPro>
        )
    }

    return (
        <>

            <Desktop>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
            </Desktop>
        </>
    );
}

export const RightSideAds = () => {

    const isIPadPro = useMediaQuery({minWidth: 1024, maxWidth: 1366})

    if (isIPadPro) {
        return (
            <IPadPro>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
            </IPadPro>
        )
    }

    return (
        <>
            <Desktop>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
            </Desktop>
        </>
    );
}



export const AdsMovieDetailLeftSide = () => {
    return (
        <Desktop>
            <Banner160x600/>
        </Desktop>
    )
}

export const AdsMovieDetailRightSide = () => {
    return (
        <Desktop>
            <Banner160x600/>
        </Desktop>
    )
}



export const AdsSearchLeftSide = () => {


    const isIPadPro = useMediaQuery({minWidth: 1024, maxWidth: 1366})

    if (isIPadPro) {
        return (
            <IPadPro>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
            </IPadPro>
        )
    }

    return (
        <>
            <Desktop>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
            </Desktop>
        </>
    );
}

export const AdsSearchRightSide = () => {

    const isIPadPro = useMediaQuery({minWidth: 1024, maxWidth: 1366})

    if (isIPadPro) {
        return (
            <IPadPro>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
                <Banner160x300/>
            </IPadPro>
        )
    }

    return (
        <>
            <Desktop>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
                <Banner300x250/>
            </Desktop>
        </>
    );
}

