import posterplaceholder from '../../images/placeholder.png';
import React from "react";
import {Image} from "@nextui-org/react";

const PosterWithPlaceholder = ({src}) => {
    return (
        <div className="h-full object-cover">
            <Image src={src}
                   alt="movie-poster"
                   className="rounded-lg w-full h-full object-cover"
                   loading="lazy"
                   fallback={posterplaceholder}
                   placeholder={
                       <img src={posterplaceholder} alt="placeholder" className="w-full h-full object-cover"/>
                   }
            />
        </div>
    )
}

export default PosterWithPlaceholder;