import React from 'react';
import {image185} from "../../utils/moviedb";
import PosterWithPlaceholder from "./PosterWithPlaceholder";
import {MovieNavigation} from "../../Navigation";
import {Card, CardHeader, Image} from "@nextui-org/react";

const MovieCard = ({movie}) => {


    return (
        <div
            className="group relative overflow-hidden transition-transform duration-300 transform hover:scale-110 mb-2">


            <MovieNavigation
                movie_id={movie.id} movie_title={movie.title}
                className="dark:bg-[#1f1f1f] relative group select-none overflow-hidden transition-transform duration-300 transform hover:scale-110 mb-2 bg-white aspect-Poster"
            >
                <Card radius={"none"} className={"bg-transparent"}>

                    <PosterWithPlaceholder src={image185(movie.poster_path)}/>

                    {movie.vote_average !== 0 &&
                        <div className="absolute right-0 bg-yellow-400 rounded-tr-lg rounded-bl-lg">
                            <p className="text-black text-xs font-semibold text-center py-1 px-2.5">
                                {movie.vote_average.toFixed(2)}
                            </p>
                        </div>
                    }

                    <div
                        className="rounded-b-lg absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-2">
                        <p className="text-white text-sm font-semibold text-center tracking-wide drop-shadow-lg">
                            {movie.title.length > 40 ? movie.title.split('').slice(0, 20).join('') + '...' : movie.title}
                        </p>
                    </div>
                </Card>
            </MovieNavigation>
        </div>
    );
};

export default MovieCard;