import {Helmet} from "react-helmet";
import React from "react";

export const MovieDetailHead = ({movie}) => {

    return (<Helmet>
        <title>{movie.title}</title>
        <meta name="description" content={movie.overview}/>
        <meta name="keywords"
              content={`${movie.genres.map(genre => genre.name).join(', ')}, ${movie.keywords.keywords.map(keyword => keyword.name).join(', ')}`}/>

    </Helmet>);
}


export const MovieDownloadHead = ({movie}) => {
    return (<Helmet>
        <title>Download - {movie.title}</title>
        <meta name="description" content={movie.overview}/>
        {/*<meta name="keywords" content={movie.genres.map(genre => genre.name).join(', ')}/>*/}
        {/*<meta name="keywords"*/}
        {/*      content={`${movie.genres.map(genre => genre.name).join(', ')}, ${movie.keywords.keywords.map(keyword => keyword.name).join(', ')}`}/>*/}
        <meta name="keywords"
              content={`${movie.genres.map(genre => genre.name).join(', ')}, ${movie.keywords.keywords.map(keyword => keyword.name).join(', ')}`}/>

    </Helmet>);
}


export const HomeHead = ({name}) => {
    return (
        <Helmet>
            <title>{name} - Watch Movies Time</title>
            <meta name="description" content="Home page"/>
        </Helmet>
    );
}