import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    fetchDailyTrendingMovies,
    fetchNowPlayingMovies,
    fetchPopularMovies, fetchTopRatedMovies, fetchUpcomingMovies,
    fetchWeeklyTrendingMovies
} from "../utils/moviedb";
import MovieCard from "../Components/MovieCard";
import {Pagination} from "@nextui-org/react";
import PreLoader from "../Components/PreLoader";
import {
    LeftSideAds,
    PaginationBottomAds,
    PaginationTopAds,
    RightSideAds,
    TopAds
} from "../ads/AdsContainer";
import {useMediaQuery} from "react-responsive";


const Home = ({currentPage, setCurrentPage, setNavPathName}) => {

    const {type, page} = useParams();

    const navigate = useNavigate();

    const [movieData, setMovieData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [totalPages, setTotalPages] = useState(500);


    const getMovieData = async (type, page = 1) => {
        let data;
        switch (type) {
            case 'daily-trending':
                data = await fetchDailyTrendingMovies(page);
                break;
            case 'now-playing':
                data = await fetchNowPlayingMovies(page);
                break;
            case 'weekly-trending':
                data = await fetchWeeklyTrendingMovies(page);
                break;
            case 'popular':
                data = await fetchPopularMovies(page);
                break;
            case 'top-rated':
                data = await fetchTopRatedMovies(page);
                break;
            case 'upcoming':
                data = await fetchUpcomingMovies(page);
                break;
            default:
                data = await fetchDailyTrendingMovies(page);
        }
        setMovieData(data.results);
        if (data.total_pages > 500)
            setTotalPages(500);
        else
            setTotalPages(data.total_pages);
    }

    useEffect(() => {
        if (currentPage === 0) return;
        navigate(`/${type}/${currentPage}`);
    }, [currentPage]);


    useEffect(() => {
        setIsLoaded(false);
        if (type === undefined) {
            setNavPathName('daily-trending');
            return;
        }
        getMovieData(type, currentPage).then(() => setIsLoaded(true));
    }, [type, currentPage]);

    return (
        <section
            className="pt-20 flex-wrap text-center justify-center mb-16"
        >


            {
                isLoaded ? (
                    <div
                        className="flex justify-evenly"
                    >
                        <div>
                            <LeftSideAds/>
                        </div>

                        <div>
                            <div className={"flex justify-center"}>
                                <TopAds/>
                            </div>

                            <div
                                className={"max-w-[940px] mx-auto px-4"}>
                                <div
                                    className="flex flex-wrap xs:gap-4 gap-[14px] justify-center my-5"
                                >

                                    {movieData.map((movie,index) => (
                                        <div
                                            className="flex flex-col xs:gap-4 gap-2 lg:mb-6 md:mb-5 sm:mb-4 mb-[10px]"
                                            key={movie.id}>
                                            <MovieCard movie={movie}/>
                                        </div>
                                        // <React.Fragment key={movie.id}>
                                        //     <div className="flex flex-col xs:gap-4 gap-2 lg:mb-6 md:mb-5 sm:mb-4 mb-[10px]">
                                        //         <MovieCard movie={movie} />
                                        //     </div>
                                        //     {(index + 1) % 4 === 0 && (
                                        //         <div className={"flex justify-center"}>
                                        //             <TopAds/>
                                        //         </div>
                                        //     )}
                                        // </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div>
                            <RightSideAds/>
                        </div>
                    </div>

                ) : (
                    <PreLoader/>
                )
            }


            <div className={"flex justify-center"}>
                <PaginationTopAds/>
            </div>

            <HomePagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
            />

            <div className={"flex justify-center"}>
                <PaginationBottomAds/>
            </div>

        </section>
    )
}

export default Home;

const HomePagination = ({currentPage, setCurrentPage, totalPages}) => {

    const isMobile = useMediaQuery({maxWidth: 767})

    return (
        <div className={"container"}>

            <Pagination
                color={"primary"}
                page={currentPage}
                total={totalPages}
                size={isMobile ? "sm" : "lg"}
                showControls
                variant={"flat"}
                classNames={{
                    item: ("bg-[#0b1110] text-zinc-300 hover:bg-zinc-700 hover:text-zinc-900"),
                    next: ("bg-[#0b1110] text-zinc-300 hover:bg-zinc-700 hover:text-zinc-900"),
                    prev: ("bg-[#0b1110] text-zinc-300 hover:bg-zinc-700 hover:text-zinc-900")
                }}
                onChange={(page) => {
                    window.scrollTo({top: 0});
                    setCurrentPage(page);
                }}
                className={"flex flex-wrap justify-center my-5"}
            />
        </div>
    )
}